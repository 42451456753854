import { useEffect } from "react";

import ProfileCover from "../../assets/Images/ProfileCover.jpg";
import MentorCoverPhotoLG from "../../assets/Images/ProfileCover.jpg";
import { GeneralImage } from "./memberDashboard/memberProfile/ProfileInfo";
import { url } from "../utilities/generalItems";
import {
  UserAvatar,
  RcTooltip,
  Cliploader,
  FormButton,
  TextArea,
  Scaleloader,
  currentAppUser,
  DeleteIcon,
} from "../utilities";
import {
  InputGroup,
  PhoneNumberInput,
  SelectDropdown,
  CheckBoxGroup,
} from "../utilities/newFormInputs";

import {
  EditIcon,
  EditPhotoIcon,
  FileIcon,
  UploadFileIcon,
  UploadArrowIcon,
  AdminMentorDropdownSelectArrow,
} from "../utilities/svg";

export const ProfileEditButton = ({
  onEditIconClick,
  loader,
  inputDisabled,
}) => {
  return (
    <>
      <div className="flex justify-end mt-8 text-brandDarkBlue">
        <div
          onClick={() => onEditIconClick()}
          className={`border flexCenter mb-2 py-2 px-4 rounded-xl ${
            loader ? "cursor-not-allowed" : "dashboardHover dashboardActive"
          }`}
          style={{
            boxShadow: "0px 13px 50px 0px #0000000F",
          }}
        >
          <EditIcon className="fill-current mx-2" />
          <p className="font-semibold text-sm">
            {inputDisabled ? "Edit Profile" : "Cancel Edit"}
          </p>
        </div>
      </div>
    </>
  );
};

export const ProfileCoverPhotos = ({
  userAvatarLetter,
  profileData,
  fileBeforeUpload,
  imageLoaded,
  setImageLoaded,
  profileLoaded,
  setProfileLoaded,
  getProfileBegin,
  inputDisabled,
  handleUploadImage,
}) => {
  const coverPhoto = profileData?.coverPhoto;
  const profilePhoto = profileData?.profilePhoto;

  return (
    <>
      <h1 className="font-semibold text-lg" style={{ color: "#505050" }}>
        Personal Info
      </h1>
      <div className="border-b border-solid border-memberDashboard " />
      <div className="rounded-10px shadow-memberDashboard bg-white mt-10 w-full ">
        <div className="w-full rounded-10px relative">
          {/* Cover Photo */}
          {coverPhoto === null || coverPhoto === "null" ? (
            <img
              src={
                currentAppUser() === "MEMBER_SUBSCRIBED"
                  ? ProfileCover
                  : MentorCoverPhotoLG
              }
              alt=""
              className="object-cover w-full h-40 rounded-tl-xl rounded-tr-xl "
            />
          ) : (
            !getProfileBegin &&
            coverPhoto && (
              <>
                <GeneralImage
                  photo={coverPhoto}
                  beforeUpload={fileBeforeUpload?.coverPhoto}
                  classNames="w-full h-40 rounded-tl-xl rounded-tr-xl"
                  loaded={imageLoaded}
                  setLoaded={setImageLoaded}
                />
                {!imageLoaded && <Scaleloader />}
              </>
            )
          )}

          {/* Profile photo  */}
          {profilePhoto === null || profilePhoto === "null" ? (
            <UserAvatar
              name={userAvatarLetter}
              classNames="absolute left-0 bottom-0 mx-3 transform translate-y-1/2 w-24 h-24 xl:w-32 xl:h-32 text-4xl"
            />
          ) : (
            !getProfileBegin &&
            profilePhoto && (
              <>
                <GeneralImage
                  photo={profilePhoto}
                  beforeUpload={fileBeforeUpload?.profilePhoto}
                  classNames="absolute left-0 bottom-0 mx-3 transform translate-y-1/2 w-24 h-24 xl:w-32 xl:h-32 rounded-full"
                  loaded={profileLoaded}
                  setLoaded={setProfileLoaded}
                />
                <div className="relative ">
                  {!profileLoaded && (
                    <div className=" absolute left-0 bottom-0 mx-3 transform translate-y-1/2">
                      <Scaleloader />
                    </div>
                  )}
                </div>
              </>
            )
          )}
          {!getProfileBegin && !inputDisabled && (
            <div className=" w-8 h-8 rounded-full flexCenter bg-white dashboardHover dashboardActive absolute right-0 bottom-0 mx-3 transform translate-y-1/2 ">
              <input
                type="file"
                name="coverPhoto"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => handleUploadImage(e)}
                className="absolute w-full h-full opacity-0 "
                disabled={inputDisabled}
              />

              <EditPhotoIcon className="fill-current" />
            </div>
          )}
          {!getProfileBegin && !inputDisabled && (
            <div className="w-8 h-8 rounded-full flexCenter bg-white dashboardHover dashboardActive absolute left-16  -bottom-10 xl:left-28 xl:-bottom-12 transform translate-y-1/2">
              <input
                type="file"
                name="profilePhoto"
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => handleUploadImage(e)}
                className="absolute w-full h-full opacity-0"
                disabled={inputDisabled}
              />

              <EditPhotoIcon className="fill-current" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export const BasicInformations = ({
  profileData,
  setProfileData,
  formError,
  selectInput,
  inputDisabled,
  handleInputOnchange,
}) => {
  return (
    <>
      <div className="border-b border-solid border-memberDashboard " />
      <div className="mt-20 grid-cols-1 grid md:grid-cols-2 md:gap-y-2 md:gap-x-14 1460px:gap-x-16 w-full">
        <InputGroup
          label={inputDisabled ? "First Name" : "First Name*"}
          name="firstName"
          onChange={handleInputOnchange}
          value={profileData?.firstName}
          errorMessage={formError?.firstName}
          alphabetsOnly={true}
          disabled={inputDisabled}
        />
        <InputGroup
          label={inputDisabled ? "Last Name" : "Last Name*"}
          name="lastName"
          onChange={handleInputOnchange}
          value={profileData?.lastName}
          errorMessage={formError?.lastName}
          alphabetsOnly={true}
          disabled={inputDisabled}
        />
        <PhoneNumberInput
          label={inputDisabled ? "Phone Number" : "Phone Number*"}
          name="phone"
          value={profileData?.phone}
          onChange={(value) => {
            formError.phone = "";
            value === undefined
              ? setProfileData({
                  ...profileData,
                  phone: "",
                })
              : setProfileData({
                  ...profileData,
                  phone: value,
                });
          }}
          errorMessage={formError?.phone}
          disabled={inputDisabled}
        />

        <InputGroup
          label={inputDisabled ? "Email" : "Email*"}
          name="email"
          onChange={handleInputOnchange}
          value={profileData?.email}
          errorMessage={formError?.email}
          disabled={true}
        />
      </div>
      <h1 className="mt-8 font-semibold text-lg" style={{ color: "#505050" }}>
        Address details
      </h1>
      <div className="border-b mt-2 border-solid border-memberDashboard" />
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 md:gap-y-2 md:gap-x-14">
        {!inputDisabled ? (
          <>
            <InputGroup
              label={inputDisabled ? "Address 1" : "Address 1*"}
              name="address1"
              onChange={handleInputOnchange}
              value={profileData?.address1}
              errorMessage={formError?.address1}
            />
            <InputGroup
              label={inputDisabled ? "Address 2" : "Address 2*"}
              name="address2"
              onChange={handleInputOnchange}
              value={profileData?.address2}
              errorMessage={formError?.address2}
            />
          </>
        ) : (
          <>
            {Array(2)
              .fill()
              .map((_, index) => {
                return (
                  <div key={index}>
                    <h1 className="text-base font-semibold mb-1 text-deleteIcon ">{`Address ${
                      index + 1
                    }`}</h1>
                    <div
                      className={`${
                        index > 0
                          ? profileData?.address2.length === 0
                            ? "h-2/3"
                            : "h-fit"
                          : profileData?.address1.length === 0
                          ? "h-2/3"
                          : "h-fit"
                      } appInput overflow-hidden cursor-default mb-8`}
                      style={{ backgroundColor: "#FAFAFA" }}
                    >
                      <RcTooltip
                        content={
                          index > 0
                            ? profileData?.address2
                            : profileData?.address1
                        }
                      >
                        <h1>
                          {index > 0
                            ? profileData?.address2
                            : profileData?.address1}
                        </h1>
                      </RcTooltip>
                    </div>
                  </div>
                );
              })}
          </>
        )}
        <SelectDropdown
          label={inputDisabled ? "Country" : selectInput[0].label}
          name={selectInput[0].name}
          style={{
            backgroundColor: inputDisabled ? "#f3f3f3" : "#ffffff",
          }}
          value={profileData?.country}
          onChange={(e) => {
            profileData.state = "";
            profileData.city = "";
            handleInputOnchange(e);
          }}
          optionsArray={selectInput[0].optionsArray}
          errorMessage={formError?.country}
          disabled={inputDisabled}
        />
        <SelectDropdown
          label={inputDisabled ? "State" : selectInput[1].label}
          name={selectInput[1].name}
          style={{
            backgroundColor: inputDisabled ? "#f3f3f3" : "#ffffff",
          }}
          value={profileData?.state}
          onChange={(e) => {
            profileData.city = "";
            handleInputOnchange(e);
          }}
          optionsArray={selectInput[1].optionsArray}
          errorMessage={formError?.state}
          disabled={inputDisabled}
        />
        <InputGroup
          label={inputDisabled ? "City" : selectInput[2].label}
          name={selectInput[2].name}
          value={profileData?.city}
          onChange={handleInputOnchange}
          errorMessage={formError?.city}
          disabled={inputDisabled}
        />
        <InputGroup
          label={inputDisabled ? "Zip" : "Zip*"}
          name="zip"
          onChange={handleInputOnchange}
          value={profileData?.zip}
          errorMessage={formError?.zip}
          disabled={inputDisabled}
        />
      </div>
    </>
  );
};

export const SaveChangesButton = ({
  loader,
  onBackButtonClick,
  onSubmitClick,
  classNames,
  saveButtonClassnames,
  cancelButtonClassnames,
  text,
}) => {
  return (
    <>
      <div
        className={`w-full md:w-3/4 md:ml-auto flex mt-10 space-x-6 ${classNames}`}
      >
        <FormButton
          disabled={loader}
          onClick={() => onBackButtonClick()}
          type="button"
          classNames={`bg-white text-brandBlue border border-brandBlue ${cancelButtonClassnames} ${
            loader ? "cursor-not-allowed" : "buttonHover"
          }`}
        >
          Cancel
        </FormButton>

        <FormButton
          onClick={() => onSubmitClick()}
          disabled={loader}
          classNames={`bg-brandBlue flexCenter gap-2 text-white text-sm 370px:text-lg py-3 min-w-130px 370px:min-w-170px sm:min-w-200px ${saveButtonClassnames} ${
            loader ? "cursor-wait" : "buttonHover"
          }`}
        >
          {text ? text : "Save Changes"}
          {loader && <Cliploader />}
        </FormButton>
      </div>
    </>
  );
};

//educational information dynamic validations

export const educationalDetailsFormValidation = (mentorProfileData, others) => {
  const {
    university,
    certification,
    coachingProgram,
    professionalInfo,
    focusArea,
    statement,
  } = mentorProfileData;

  const { linkedin, website } = professionalInfo;

  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );

  let validationErrors = {};

  Object.values(university).forEach(({ university }, index) => {
    if (university === null || university.trim() === "") {
      const inputKey = `university${index}`;
      validationErrors = {
        ...validationErrors,
        universities: {
          ...validationErrors.universities,
          [inputKey]: "University is required.",
        },
      };
    }
  });
  Object.values(university).forEach(({ program }, index) => {
    if (program === null || program.trim() === "") {
      const inputKey = `degree${index}`;
      validationErrors = {
        ...validationErrors,
        degrees: {
          ...validationErrors.degrees,
          [inputKey]: "Degree is required.",
        },
      };
    }
  });

  Object.values(certification).forEach(({ certification }, index) => {
    const inputKey = `certification${index}`;
    if (certification === null || certification.trim() === "") {
      validationErrors = {
        ...validationErrors,
        certifications: {
          ...validationErrors.certifications,
          [inputKey]: "Certification is required.",
        },
      };
    }
  });

  Object.values(coachingProgram).forEach(({ program }, index) => {
    const inputKey = `coachingProgram${index}`;
    if (program === null || program.trim() === "") {
      validationErrors = {
        ...validationErrors,
        coachingPrograms: {
          ...validationErrors.coachingPrograms,
          [inputKey]: "Coaching program is required.",
        },
      };
    }
  });

  if (focusArea?.length === 0) {
    validationErrors.focusArea = "Please select at least one Area of focus";
  }

  Object.values(focusArea).forEach(({ title }) => {
    if (title?.trim() === "") {
      validationErrors.focusArea = "Please enter at least one Area of focus";
    }
  });

  if (others?.trim === "") {
    validationErrors.others = "Please fill in your other goals/specialization";
  }

  if (statement?.trim() === "") {
    validationErrors.statement = "Statement is required.";
  }

  if (website?.trim() !== "" && !regex.test(website)) {
    validationErrors.website = "Invalid url format";
  }
  return validationErrors;
};

export const EducationalInformation = ({
  mentorProfileData,
  inputDisabled,
  setMentorProfileData,
  educationalDetailsformError,
  handleInputEducationalInfo,
}) => {
  return (
    <>
      <h1 className="mt-12 font-semibold text-lg" style={{ color: "#505050" }}>
        Educational information
      </h1>
      <div className="my-4 border-b border-solid border-memberDashboard " />
      <div className="w-full ">
        {mentorProfileData?.university?.map(
          ({ university, program }, index) => {
            const currentUniversityInputKey = `university${index}`;
            const currentDegreeInputKey = `degree${index}`;
            return (
              <div
                className="relative grid grid-cols-2 gap-y-2 gap-x-14 1460px:gap-x-16 animate-navbar"
                key={index}
              >
                {inputDisabled ? (
                  <div>
                    <h1 className="text-base font-semibold mb-1 text-deleteIcon">{`University ${
                      index + 1
                    }`}</h1>
                    <div
                      className="appInput h-fit overflow-hidden cursor-default"
                      style={{ backgroundColor: "#FAFAFA" }}
                    >
                      <h1>{university}</h1>
                    </div>
                  </div>
                ) : (
                  <InputGroup
                    label={
                      inputDisabled
                        ? `University ${index + 1}`
                        : `University ${index + 1}*`
                    }
                    name={`university${index}`}
                    onChange={handleInputEducationalInfo}
                    value={university}
                    errorMessage={
                      educationalDetailsformError?.universities[
                        currentUniversityInputKey
                      ]
                    }
                    alphabetsOnly={true}
                    disabled={inputDisabled}
                  />
                )}
                <InputGroup
                  label={
                    inputDisabled
                      ? `Degree ${index + 1}`
                      : `Degree ${index + 1}*`
                  }
                  name={`degree${index}`}
                  onChange={handleInputEducationalInfo}
                  errorMessage={
                    educationalDetailsformError?.degrees[currentDegreeInputKey]
                  }
                  value={program}
                  alphabetsOnly={true}
                  disabled={inputDisabled}
                />
                {!inputDisabled && index >= 1 && (
                  <DeleteIcon
                    input={true}
                    onClick={() => {
                      const { university } = mentorProfileData;
                      let universitiesArray = [...university];
                      universitiesArray.splice(index, 1);
                      setMentorProfileData({
                        ...mentorProfileData,
                        university: universitiesArray,
                      });
                    }}
                  />
                )}
              </div>
            );
          }
        )}
        {!inputDisabled && (
          <button
            className=" text-left font-semibold text-sm activeState buttonHover mb-4 "
            style={{ color: "#093B5DBF" }}
            type="button"
            onClick={() => {
              let universitiesArray = [...mentorProfileData?.university];
              universitiesArray.push({
                university: "",
                program: "",
              });
              setMentorProfileData({
                ...mentorProfileData,
                university: universitiesArray,
              });
            }}
          >
            + Add University
          </button>
        )}
      </div>
      <div className="relative grid grid-cols-2 gap-y-2 gap-x-14 1460px:gap-x-16 ">
        <div className="flex flex-col">
          {mentorProfileData?.certification.map(({ certification }, index) => {
            const currentCertificationInputKey = `certification${index}`;
            return (
              <div className="relative animate-navbar" key={index}>
                <InputGroup
                  label={
                    inputDisabled
                      ? `Certification ${index + 1}`
                      : `Certification ${index + 1}*`
                  }
                  name={`certification${index}`}
                  onChange={handleInputEducationalInfo}
                  value={certification}
                  errorMessage={
                    educationalDetailsformError?.certifications[
                      currentCertificationInputKey
                    ]
                  }
                  alphabetsOnly={true}
                  disabled={inputDisabled}
                />
                {!inputDisabled && index >= 1 && (
                  <DeleteIcon
                    input={true}
                    onClick={() => {
                      const { certification } = mentorProfileData;
                      let certificationArray = [...certification];
                      certificationArray.splice(index, 1);
                      setMentorProfileData({
                        ...mentorProfileData,
                        certification: certificationArray,
                      });
                    }}
                  />
                )}
              </div>
            );
          })}
          {!inputDisabled && (
            <button
              className="text-left font-semibold text-sm activeState buttonHover mb-4 "
              style={{ color: "#093B5DBF" }}
              type="button"
              onClick={() => {
                let certificationArray = [...mentorProfileData?.certification];
                certificationArray.push({
                  certification: "",
                });
                setMentorProfileData({
                  ...mentorProfileData,
                  certification: certificationArray,
                });
              }}
            >
              + Add Certification
            </button>
          )}
        </div>
        <div className="flex flex-col relative animate-navbar">
          {mentorProfileData?.coachingProgram.map(({ program }, index) => {
            const currentCoachingProgramInputKey = `coachingProgram${index}`;
            return (
              <div className="relative animate-navbar" key={index}>
                <InputGroup
                  label={
                    inputDisabled
                      ? `Coaching Program ${index + 1}`
                      : `Coaching Program ${index + 1}*`
                  }
                  name={`coachingProgram${index}`}
                  onChange={handleInputEducationalInfo}
                  value={program}
                  errorMessage={
                    educationalDetailsformError?.coachingPrograms[
                      currentCoachingProgramInputKey
                    ]
                  }
                  alphabetsOnly={true}
                  disabled={inputDisabled}
                />
                {!inputDisabled && index >= 1 && (
                  <DeleteIcon
                    input={true}
                    onClick={() => {
                      const { coachingProgram } = mentorProfileData;
                      let coachingProgramArray = [...coachingProgram];
                      coachingProgramArray.splice(index, 1);
                      setMentorProfileData({
                        ...mentorProfileData,
                        coachingProgram: coachingProgramArray,
                      });
                    }}
                  />
                )}
              </div>
            );
          })}
          {!inputDisabled && (
            <button
              className="text-left font-semibold text-sm activeState buttonHover mb-4 "
              style={{ color: "#093B5DBF" }}
              type="button"
              onClick={() => {
                let coachingProgramArray = [
                  ...mentorProfileData?.coachingProgram,
                ];
                coachingProgramArray.push({
                  program: "",
                });
                setMentorProfileData({
                  ...mentorProfileData,
                  coachingProgram: coachingProgramArray,
                });
              }}
            >
              + Add Coaching Program
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export const FocusAreaSelect = ({
  focusArea,
  setMentorProfileData,
  formError,
  setFormError,
  others,
  setOthers,
  checkedOptions,
  setCheckedOptions,
}) => {
  useEffect(() => {
    const focusAreaSelect = () => {
      let focusAreas = [];
      focusArea.map(({ id, title }) => {
        if (checkedOptions.includes(id)) {
          if (id === 6) {
            focusAreas.push({ id: id, title: others });
          } else {
            focusAreas.push({ id: id, title: title });
          }
        }
        return "";
      });
      setMentorProfileData((data) => ({
        ...data,
        focusArea: focusAreas,
      }));
    };
    focusAreaSelect();
  }, [checkedOptions, focusArea, setMentorProfileData, others]);

  const onChange = (id) => {
    if (checkedOptions.includes(id)) {
      setCheckedOptions(
        checkedOptions.filter((optionId) => {
          return optionId !== id;
        })
      );
    } else {
      setCheckedOptions([...checkedOptions, id]);
    }
  };
  return focusArea?.map(({ id, title }) => {
    const optionChecked = checkedOptions.includes(id);
    return (
      <div key={id} className="p-2">
        <div className="flex items-center px-4 mb-3">
          <CheckBoxGroup
            onClick={() => {
              onChange(id);
              let error = formError;
              delete error[["focusArea"]];
              setFormError({
                ...formError,
                ...error,
              });
            }}
            state={optionChecked}
            name="focusArea"
          />
          <p className="font-bold" style={{ color: "#393939" }}>
            {title}
          </p>
        </div>
        {id === 6 && checkedOptions.includes(6) ? (
          <TextArea
            placeholder="Enter other focus areas"
            name="others"
            value={others}
            onChange={(e) => {
              let error = formError;
              delete error[["focusArea"]];
              setFormError({
                ...formError,
                ...error,
              });
              setOthers(e.target.value);
            }}
            classNames="mb-0"
            rows="7"
            errorMessage={formError?.others}
            setValue={(value) => {
              setOthers(value);
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  });
};

export const UploadResumeComponent = ({
  mentorProfileData,
  inputDisabled,
  mentorUploadedResumeName,
  setMentorUploadedResumeName,
  handleUploadFile,
}) => {
  return (
    <div
      className={`appInput flexCenter flex-col h-full relative buttonHover mt-7 ${
        inputDisabled ? "" : "activeState"
      }`}
    >
      <div
        className="rounded-full w-14 h-14 flexCenter"
        style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)" }}
      >
        <UploadFileIcon />
      </div>

      <p className="border-2 border-brandBlue text-brandBlue px-2 animate-navbar rounded-xl text-sm mt-4">
        {mentorUploadedResumeName === ""
          ? `${
              mentorProfileData?.firstName
            }'s ${mentorProfileData?.resume.slice(
              9,
              mentorProfileData?.resume.length
            )}`
          : mentorUploadedResumeName}
      </p>

      <h1 style={{ color: "#A0A0A0" }} className="my-4 font-medium">
        {inputDisabled ? "" : "Drag & Drop your resume here"}
      </h1>
      {inputDisabled ? (
        <FormButton
          type="button"
          classNames="bg-brandBlue text-white w-3/4 py-2 activeState"
          onClick={() =>
            window.open(
              `${url}/file?path=${mentorProfileData?.resume}`,
              "_blank"
            )
          }
        >
          <FileIcon />
          View Resume
        </FormButton>
      ) : (
        <FormButton
          type="button"
          classNames="bg-brandBlue text-white w-2/5 py-2"
        >
          <UploadArrowIcon />
          {mentorUploadedResumeName !== "" ? "Change" : "Upload"}
        </FormButton>
      )}
      {inputDisabled ? (
        ""
      ) : (
        <input
          type="file"
          name="resume"
          accept="application/pdf, image/png, image/jpg, image/jpeg, application/msword, .docx"
          onChange={(e) => {
            setMentorUploadedResumeName(
              e.target.files[0] ? e.target.files[0]?.name : ""
            );
            handleUploadFile(e);
          }}
          className="absolute w-full h-full cursor-pointer opacity-0"
        />
      )}
    </div>
  );
};

export const ProfessionalInformation = ({
  mentorProfileData,
  setMentorProfileData,
  handleInputEducationalInfo,
  educationalDetailsformError,
  setEducationalDetailsFormError,
  inputDisabled,
  focusAreaPick,
  setFocusAreaPick,
  mentorUploadedResumeName,
  setMentorUploadedResumeName,
  handleUploadFile,
  handleInputOnchange,
}) => {
  return (
    <>
      <h1 className="mt-12 font-semibold text-lg" style={{ color: "#505050" }}>
        Professional information
      </h1>
      <div className="my-4 border-b border-solid border-memberDashboard " />
      <div className="grid grid-cols-2 gap-y-2 gap-x-14 1460px:gap-x-16 w-full">
        <InputGroup
          label={"Linkedin url"}
          name="linkedin"
          onChange={handleInputEducationalInfo}
          value={mentorProfileData?.professionalInfo?.linkedin}
          errorMessage={educationalDetailsformError?.linkedin}
          disabled={inputDisabled}
        />
        <div className="flex flex-col mb-4"></div>
        <UploadResumeComponent
          inputDisabled={inputDisabled}
          mentorUploadedResumeName={mentorUploadedResumeName}
          setMentorUploadedResumeName={setMentorUploadedResumeName}
          handleUploadFile={handleUploadFile}
          mentorProfileData={mentorProfileData}
        />
        <TextArea
          name="statement"
          label={inputDisabled ? "Personal Statement" : "Personal Statement*"}
          value={mentorProfileData?.statement}
          disabled={inputDisabled}
          onChange={(e) => {
            let error = educationalDetailsformError;
            delete error[[e.target.name]];
            setEducationalDetailsFormError({
              ...educationalDetailsformError,
              ...error,
            });
            handleInputOnchange(e);
          }}
          classNames={`mb-0 ${inputDisabled ? "resize-none" : ""}`}
          rows="7"
          errorMessage={educationalDetailsformError.statement}
          setValue={(value) => {
            setMentorProfileData({
              ...mentorProfileData,
              statement: value,
            });
          }}
        />
        {inputDisabled ? (
          <div className="mt-10">
            <h1 className="font-bold text-lg mb-4">Focus Areas</h1>
            <div className="flex flex-col">
              {mentorProfileData?.focusArea?.map(({ title }, index) => (
                <div key={index} className="flex items-center space-x-4">
                  <div
                    className="rounded-full w-2 h-2 "
                    style={{
                      backgroundColor: "#0181AC",
                      width: "11px",
                      height: "11px",
                    }}
                  />

                  <h1>{title}</h1>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            className="p-4 mt-10 flex justify-between items-center rounded cursor-pointer hover:font-bold"
            onClick={() => {
              setFocusAreaPick(!focusAreaPick);
            }}
            style={{
              border:
                !inputDisabled && focusAreaPick
                  ? "2px solid #007CA5"
                  : "1px solid #B9B9BB",
            }}
          >
            <h1>Select Focus Area </h1>
            {!inputDisabled && focusAreaPick ? (
              <AdminMentorDropdownSelectArrow className=" rotate-180 " />
            ) : (
              <AdminMentorDropdownSelectArrow />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const focusArea = [
  { id: 1, title: "General Life Coaching" },
  { id: 2, title: "Career/Professional" },
  { id: 3, title: "Financial" },
  { id: 4, title: "Entrepreneurship" },
  { id: 5, title: "Wellness" },
  { id: 6, title: "Others" },
];
